import { DataStore } from "@aws-amplify/datastore";
import { CarData, ChargeCodes, CommuteEntries, Mileage, Users} from '../models';
import React from "react";
import CustomCommuteEntriesCreateForm from "./CustomCommuteEntriesCreateForm";
import { SelectField } from "@aws-amplify/ui-react";

async function getCars() {
    console.log("Querying Cars")
    const userCars = await DataStore.query(CarData);
    return userCars;
}

async function getMileage() {
  console.log("Querying Mileage")
  const mileage = await DataStore.query(Mileage);
  return mileage;
}

async function getAddresses() {
  console.log("Querying Commutes")
  const userCommutes = await DataStore.query(CommuteEntries);
  let addresses = userCommutes.map(commute=>commute.endAddress);
  addresses = [...addresses, ...userCommutes.map(commute=>commute.startAddress)];
  const uniqueAddresses = addresses.filter((val, id, array) => {
    return array.indexOf(val) === id;
  });
  const addressOptions = uniqueAddresses.map((address) => {
    return {label: address};
  })
  return addressOptions;
}
async function getUsers() {
  const users = await DataStore.query(Users);
  console.log(users);
  return users;
}

export class CustomCommuteForm extends React.Component {

    constructor(props) {
      super(props);
      this.state = {carList: [], defaultCar: "", mileage: [], addresses: [], selectedFilterUser: null, dropdownOptions: [{value: "all", label: "All Users"}]}
      getMileage().then((response) => {
        this.setState({mileage: response});
      });
      getAddresses().then((response) => {
        this.setState({addresses: response});
      })
      
      getUsers().then((usersDataStore) => {
        var dropdownOptions = [];
        for (let x in usersDataStore) {
          if (usersDataStore[x].owner == null){
            dropdownOptions.push({ value:usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
          } else {
            dropdownOptions.push({ value:usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
          }
        }
        this.setState({dropdownOptions: dropdownOptions})
        if (props.userID == null){
          this.setState({selectedFilterUser: dropdownOptions[0].value})
        } else {
          this.setState({selectedFilterUser: props.userID})
        }
        
        getCars().then((response) => {
          this.setState({carList: response.filter(car => car.usersID.includes(this.state.selectedFilterUser))})
        })
      })
      
    }

    userSelectField(adminView){
      if(!adminView){
        return null
      }
      return (
        <SelectField
          value={this.state.selectedFilterUser}
          onChange={(e) => {
            if (e.target.value === "all"){
              this.setState({selectedFilterUser:null})
            } else {
              this.setState({selectedFilterUser: e.target.value})
            }
            getCars().then((response) => {
              this.setState({carList: response.filter(car => car.usersID.includes(this.state.selectedFilterUser))})
            })
          }}>
          {this.state.dropdownOptions.map(({value, label}, index) => <option key={value} value={value}>{label}</option>)}
        </SelectField>);
    }
  
    render () {
      return (
        <div>
          <div style={({paddingLeft:"2vw", paddingRight:"2vw"})}>
                    {this.userSelectField(this.props.adminView)}
                </div>
        <CustomCommuteEntriesCreateForm
          justifyContent='center'
          date={new Date().toLocaleDateString('sv', { timeZoneName: 'short' }).slice(0, 10)}
          carname={this.props.carName}
          chargecode={this.props.chargeCode}
          startaddress={this.props.startAddress}
          endaddress={this.props.endAddress}
          odometerstart={this.props.odometerStart}
          odometerend={this.props.odometerEnd}
          miles={this.props.miles}
          parking={this.props.parking}
          tolls={this.props.tolls}
          total={this.props.total}
          purpose={this.props.purpose}
          roundtrip={this.props.roundtrip}
          overrides={{ 
            CarData: {
              options: this.state.carList.map(car => car.carName? car.carName: (car.make + ' ' + car.model))},
            startAddress: {options: this.state.addresses},
            endAddress: {options: this.state.addresses}
            }}
          onValidate={{
            odometerStart: (value, validationResponse) => {
              if (value < 0) {
                return {
                  hasError: true,
                  errorMessage: "The value must be greater than 0"
                }
              }
              return validationResponse;
            },
            odometerEnd: (value, validationResponse) => {
              if (value < 0) {
                return {
                  hasError: true,
                  errorMessage: "The value must be greater than 0"
                }
              }
              return validationResponse;
            },
            parking: (value, validationResponse) => {
              if (value < 0) {
                return {
                  hasError: true,
                  errorMessage: "The cost of parking must not be negative"
                }
              }
              return validationResponse
            },
            tolls: (value, validationResponse) => {
              if (value < 0) {
                return {
                  hasError: true,
                  errorMessage: "The cost of tolls must not be negative"
                }
              }
              return validationResponse
            }
          }}
          onSubmit= {(fields) => {
            console.log("Started Submitting")

            // calculating total cost
            var userId = this.props.userID;
            if (this.state.selectedFilterUser) {
              userId = this.state.selectedFilterUser;
            }
            const year = parseInt(fields.date.slice(0,4));
            // const year = fields.date.getFullYear();
            const currMileage = this.state.mileage.filter(mileage => mileage.year === year)[0];
            const mileageRate = currMileage.rate;
            if(fields.parking === ""){
              console.log("parking")
              fields.parking = 0;
            }
            if(fields.tolls === ""){
              console.log("tolls")
              fields.tolls = 0;
            }
            fields.total = Math.ceil((mileageRate * fields.miles + fields.parking + fields.tolls) * 100) / 100;

            const selectedCar = this.state.carList.filter(car => (car.carName? car.carName: (car.make + ' ' + car.model))  === fields.CarData)[0];
            console.log("selected car:", selectedCar)
            let charge = "";
            if (fields.chargeCode === ChargeCodes.OVERHEAD) {
              charge = "Overhead";
            } else if (fields.chargeCode === ChargeCodes.UNALLOWABLE) {
              charge = "Unallowable";
            } else if (fields.chargeCode === ChargeCodes.GA) {
              charge = "G&A";
            }
            const updatedFields = Object.assign(fields, {cardataID: selectedCar.id, usersID: userId, carNameString: selectedCar.carName? selectedCar.carName : (selectedCar.make + " " + selectedCar.model), chargeCodeString: charge});
            console.log("update fields", updatedFields)
            return updatedFields;
          }}
          onSuccess= {() => {
            console.log("Successful upload")
            if (this.props.closeDialog){
              this.props.closeDialog();
            } else {
              this.props.navHook("/PastEntries");
            }
            
          }}
          onError= {(error,msg) => {
            console.log("error")
            console.log(error)
            console.log(msg)
          }}
          onCancel= {() => {
            if (this.props.closeDialog){
              this.props.closeDialog();
            } else {
              this.props.navHook("/PastEntries");
            }
          }}
          clearOnSuccess = {true}
          />
          </div>
      )
    }
}
  