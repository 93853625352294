import { DataStore } from "aws-amplify";
import React from "react";
import { CommuteEntries } from "../models";
import { CustomCommuteForm } from "./CustomCommuteForm";
import { Flex } from '@aws-amplify/ui-react';

async function getCommute(commuteid) {
    const commute = await DataStore.query(CommuteEntries, commuteid);
    return commute;
}

export class DuplicateCommuteForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            carName: "",
            chargeCode: "",
            startAddress: "",
            endAddress: "",
            odometerStart: "",
            odometerEnd: "",
            miles: "",
            parking: "",
            tolls: "",
            total: "",
            commuteLoaded: false
        }
    }

    componentDidMount() {
        getCommute(this.props.commuteid).then((response) => {
            this.setState({
                carName: response.carNameString,
                chargeCode: response.chargeCode,
                startAddress: response.startAddress,
                endAddress: response.endAddress,
                odometerStart: response.odometerStart,
                odometerEnd: response.odometerEnd,
                miles: response.miles,
                parking: response.parking,
                tolls: response.tolls,
                total: response.total,
                commuteLoaded: true
            })
        })
    }

    render() {
        if(this.state.commuteLoaded){
            return (
            <Flex direction="column">
                <CustomCommuteForm 
                    userID={this.props.userID}
                    carName={this.state.carName}
                    chargeCode={this.state.chargeCode}
                    startAddress={this.state.startAddress}
                    endAddress={this.state.endAddress}
                    odometerStart={this.state.odometerStart}
                    odometerEnd={this.state.odometerEnd}
                    miles={this.state.miles}
                    parking={this.state.parking}
                    tolls={this.state.tolls}
                    total={this.state.total}
                    navHook={this.props.navHook}
                    closeDialog={this.props.closeDialog}
                ></CustomCommuteForm>
            </Flex>
            )
        } else {
            return (
                <div>Loading...</div>
            )
        }
        
    }
}