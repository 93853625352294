import { DataStore } from "aws-amplify";
import React from "react";
import { CarData } from "../models";
import CarDataUpdateForm from "../ui-components/CarDataUpdateForm"

async function getCar(carid) {
    const car = await DataStore.query(CarData, carid);
    console.log(carid)
    return car;
}

export class UpdateCarForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            car: {},
            carLoaded: false
        }
    }

    componentDidMount() {
        getCar(this.props.carid).then((response) => {
            this.setState({
                car: response,
                carLoaded: true
            })
            console.log(response)
        })
    }

    render() {
        if(this.state.carLoaded){
            return (
                <CarDataUpdateForm 
                    carData={this.state.car}
                    onSubmit= {(fields) => {
                      const updatedFields = Object.assign(fields, {usersID: this.props.userID});
                      return updatedFields;
                    }}
                      onSuccess= {() => {
                        console.log("Successful upload")
                        if (this.props.closeDialog){
                            this.props.closeDialog();
                          } else {
                            this.props.navHook("/Cars");
                          }
                      }}
                      onError= {(error,msg) => {
                        console.log("error")
                        console.log(error)
                        console.log(msg)
                      }}>

                </CarDataUpdateForm>
            )
        } else {
            return (
                <div>Loading...</div>
            )
        }
        
    }
}