import React from "react";
import CustomCarDataCreateForm from "./CustomCarDataCreateForm"
import { SelectField } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { Users } from "../models";

async function getUsers() {
    const users = await DataStore.query(Users);
    return users;
  }

export class CustomCarForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedFilterUser: null, dropdownOptions: [{value: "all", label: "All Users"}]}

        getUsers().then((usersDataStore) => {
            var dropdownOptions = [];
            for (let x in usersDataStore) {
              // remove users with null names from list:
              if (usersDataStore[x].last_name != null){
                if (usersDataStore[x].owner == null){
                  dropdownOptions.push({ value:usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
                } else {
                  dropdownOptions.push({ value:usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
                }  
              }
              
            }
            this.setState({dropdownOptions: dropdownOptions})
            if (props.userID == null){
              this.setState({selectedFilterUser: dropdownOptions[0].value})
            } else {
              this.setState({selectedFilterUser: props.userID})
            }
            
          })
    }

    userSelectField(adminView){
        if(!adminView){
          return null
        }
        return (
            <SelectField
                label="Assign to User:"
                value={this.state.selectedFilterUser}
                onChange={(e) => {
                    if (e.target.value === "all"){
                    this.setState({selectedFilterUser:null})
                    } else {
                    this.setState({selectedFilterUser: e.target.value})
                    }
                
                }}>
                {this.state.dropdownOptions.map(({value, label}, index) => <option key={value} value={value}>{label}</option>)}
            </SelectField>);
    }

    render() {
        return (
            <div>
                <div style={({paddingLeft:"2vw", paddingRight:"2vw"})}>
                    {this.userSelectField(this.props.adminView)}
                </div>
                <CustomCarDataCreateForm 
                  onSubmit={(fields) => {
                    var userId = this.props.userID;
                    if (this.state.selectedFilterUser) {
                    userId = this.state.selectedFilterUser;
                    }
                    const updatedFields = Object.assign(fields, {usersID: userId});
                    return updatedFields;
                  }}
                  onSuccess={this.props.handleClose}
                  onCancel={this.props.handleClose}
                />
            </div>
        )
    }
}