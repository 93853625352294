import * as React from "react";
import {
  Autocomplete,
  Button,
  Flex,
  Grid,
  HighlightMatch,
  SelectField,
  TextField,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { CommuteEntries } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
import { Loader } from "@googlemaps/js-api-loader";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const loader = new Loader({
  apiKey: "AIzaSyDdp4hbKnYcbDktOj13e_UjRaqIA8MOdOk",
  version: "weekly",
});

const mapOptions = {
  center: { lat: -34.397, lng: 150.644 },
    zoom: 8,
};

export default function CustomCommuteEntriesCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    date: "",
    CarData: undefined,
    chargeCode: undefined,
    startAddress: "",
    endAddress: "",
    odometerStart: "",
    odometerEnd: "",
    miles: "",
    parking: "",
    tolls: "",
    total: "",
    purpose: "",
    roundtrip: false,
  };
  if(props.date){
    initialValues.date=props.date;
  }
  if(props.carname){
    initialValues.CarData=props.carname;
  }
  if(props.chargecode){
    initialValues.chargeCode=props.chargecode;
  }
  if(props.startaddress){
    initialValues.startAddress=props.startaddress;
  }
  if(props.endaddress){
    initialValues.endAddress=props.endaddress;
  }
  if(props.odometerstart){
    initialValues.odometerStart=props.odometerstart;
  }
  if(props.odometerend){
    initialValues.odometerEnd=props.odometerend;
  }
  if(props.miles){
    initialValues.miles=props.miles;
  }
  if(props.parking){
    initialValues.parking=props.parking;
  }
  if(props.tolls){
    initialValues.tolls=props.tolls;
  }
  if(props.total){
    initialValues.total=props.total;
  }
  if(props.purpose){
    initialValues.purpose=props.purpose;
  }
  if(props.roundtrip){
    initialValues.roundtrip=props.roundtrip;
  }
  const [date, setDate] = React.useState(initialValues.date);
  const [dateP, setDateP] = React.useState(new Date());
  const [CarData, setCarData] = React.useState(initialValues.CarData);
  const [chargeCode, setChargeCode] = React.useState(initialValues.chargeCode);
  const [startAddress, setStartAddress] = React.useState(
    initialValues.startAddress
  );
  const [endAddress, setEndAddress] = React.useState(initialValues.endAddress);
  const [odometerStart, setOdometerStart] = React.useState(
    initialValues.odometerStart
  );
  const [odometerEnd, setOdometerEnd] = React.useState(
    initialValues.odometerEnd
  );
  const [miles, setMiles] = React.useState(initialValues.miles);
  const [parking, setParking] = React.useState(initialValues.parking);
  const [tolls, setTolls] = React.useState(initialValues.tolls);
  const [total, setTotal] = React.useState(initialValues.total);
  const [purpose, setPurpose] = React.useState(initialValues.purpose);
  const [roundtrip, setRoundTrip] = React.useState(initialValues.roundtrip);
  const [errors, setErrors] = React.useState({});
  const [distance, setDistance] = React.useState(0);

  const resetStateValues = () => {
    setDate(initialValues.date);
    setDateP(new Date());
    setCarData(initialValues.CarData);
    setChargeCode(initialValues.chargeCode);
    setStartAddress(initialValues.startAddress);
    setEndAddress(initialValues.endAddress);
    setOdometerStart(initialValues.odometerStart);
    setOdometerEnd(initialValues.odometerEnd);
    setMiles(initialValues.miles);
    setParking(initialValues.parking);
    setTolls(initialValues.tolls);
    setTotal(initialValues.total);
    setPurpose(initialValues.purpose);
    setRoundTrip(initialValues.roundtrip);
    setErrors({});
  };
  const validations = {
    date: [{ type: "Required" }],
    CarData: [],
    chargeCode: [],
    startAddress: [{ type: "Required" }],
    endAddress: [{ type: "Required" }],
    odometerStart: [],
    odometerEnd: [],
    miles: [
      { type: "Required" },
      {
        type: "GreaterThanNum",
        numValues: [0],
        validationMessage: "Miles traveled must be greater than 0",
      },
    ],
    parking: [],
    tolls: [],
    total: [],
    purpose: [],
    roundtrip: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value = getDisplayValue
      ? getDisplayValue(currentValue)
      : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const findRoute = async (from, to) => {
    loader.importLibrary('maps').then(async () => {
      const origin1 = from;
      const destinationA = to;
      const request = {
        origins: [origin1],
        destinations: [destinationA],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      }
    
      const service = new window.google.maps.DistanceMatrixService();
    
      service.getDistanceMatrix(request).then((response) => {
        console.log(response);
        const milesField = document.getElementById("Miles");
        if (response.rows[0].elements[0].status==="OK") {
          setDistance(parseFloat(response.rows[0].elements[0].distance.text));
          var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
          nativeInputValueSetter.call(milesField, parseFloat(response.rows[0].elements[0].distance.text));
          var event = new Event('input', { bubbles: true });
          milesField.dispatchEvent(event);
        }
        else {
          if (response.originAddresses[0] === "") {
            window.alert("Invalid Start Address")
          } else if ( response.destinationAddresses[0] === "") {
            window.alert("Invalid End Address")
          } else {
            window.alert("Error Calculating Trip Distance")
          }
        } 
      })
    });
  }
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          date,
          CarData,
          chargeCode,
          startAddress,
          endAddress,
          odometerStart,
          odometerEnd,
          miles,
          parking,
          tolls,
          total,
          purpose,
          roundtrip,
        };
        console.log(modelFields)
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (modelFields.odometerStart == "" && modelFields.odometerEnd > 0) {
          modelFields.odometerStart = modelFields.odometerEnd - modelFields.miles
        }
        if (modelFields.odometerStart > 0 && modelFields.odometerEnd == "") {
          modelFields.odometerEnd = modelFields.odometerStart + modelFields.miles
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new CommuteEntries(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            if (modelFields.roundtrip) {
              const oldStart = modelFields.startAddress
              modelFields.startAddress = modelFields.endAddress
              modelFields.endAddress = oldStart
              modelFields.odometerStart = modelFields.odometerEnd
              modelFields.odometerEnd = modelFields.odometerStart + modelFields.miles
              modelFields.parking = 0
              modelFields.tolls = 0
              await DataStore.save(new CommuteEntries(modelFields));
              if (onSuccess) {
                onSuccess(modelFields);
              }

            }
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CommuteEntriesCreateForm")}
      {...rest}
    >
      <CheckboxField
        value="yes"
        checked={roundtrip}
        onChange={(e) => setRoundTrip(e.target.checked)}
        label="Round Trip"
      ></CheckboxField>
      <div>Date
      <span style={{ color: "red" }}>*</span>
      </div>
      <DatePicker
        label="Date"
        isRequired={true}
        isReadOnly={false}
        type="date"
        selected={dateP}
        onChange={(date) => {
          console.log("Change")
          let value = date.toLocaleDateString('sv', { timeZoneName: 'short' }).slice(0, 10);
          if (onChange) {
            const modelFields = {
              date: value,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.date ?? value;
            console.log(modelFields)
            console.log("Result:", result)
          }
          if (errors.date?.hasError) {
            runValidationTasks("date", value);
          }
          setDateP(date)
          setDate(value)
        }}
        wrapperClassName="date-picker"
      />
      <SelectField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Car</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        placeholder="Please select a vehicle"
        value={CarData}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              date,
              CarData: value,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.CarData ?? value;
          }
          if (errors.CarData?.hasError) {
            runValidationTasks("CarData", value);
          }
          setCarData(value);
        }}
        onBlur={() => runValidationTasks("CarData", CarData)}
        errorMessage={errors.CarData?.errorMessage}
        hasError={errors.CarData?.hasError}
        {...getOverrideProps(overrides, "CarData")}
      ></SelectField>
      <SelectField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Charge code</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        placeholder="Please select an option"
        isDisabled={false}
        isRequired={true}
        value={chargeCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode: value,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.chargeCode ?? value;
          }
          if (errors.chargeCode?.hasError) {
            runValidationTasks("chargeCode", value);
          }
          setChargeCode(value);
        }}
        onBlur={() => runValidationTasks("chargeCode", chargeCode)}
        errorMessage={errors.chargeCode?.errorMessage}
        hasError={errors.chargeCode?.hasError}
        {...getOverrideProps(overrides, "chargeCode")}
      >
        <option
          children="G&A"
          value="GA"
          {...getOverrideProps(overrides, "chargeCodeoption0")}
        ></option>
        <option
          children="Overhead"
          value="OVERHEAD"
          {...getOverrideProps(overrides, "chargeCodeoption1")}
        ></option>
        <option
          children="Unallowable"
          value="UNALLOWABLE"
          {...getOverrideProps(overrides, "chargeCodeoption2")}
        ></option>
      </SelectField>
      <Autocomplete
        id="StartAddress"
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Start Address</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        labelHidden={false}
        hasSearchIcon={false}
        isRequired={true}
        isReadOnly={false}
        value={startAddress}
        renderOption={(option, value) => {
          return <HighlightMatch query={value}>{option?.label}</HighlightMatch>;
        }}
        optionFilter={(option, value) => {
          return option?.label?.includes('');
        }}
        onClear={() => {
          setStartAddress('');
        }}
        onSelect={(e) => {
          let value = e.label;
          if (errors.startAddress?.hasError) {
            runValidationTasks("startAddress", value);
          }
          setStartAddress(value);
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress: value,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.startAddress ?? value;
          }
          if (errors.startAddress?.hasError) {
            runValidationTasks("startAddress", value);
          }
          setStartAddress(value);
        }}
        onBlur={() => {
          runValidationTasks("startAddress", startAddress);
          if (startAddress && endAddress) {
            findRoute(startAddress, endAddress)
          }
        }}
        errorMessage={errors.startAddress?.errorMessage}
        hasError={errors.startAddress?.hasError}
        {...getOverrideProps(overrides, "startAddress")}
      ></Autocomplete>
      <Autocomplete
        id="DestAddress"
        label={
          <span style={{ display: "inline-flex" }}>
            <span>End Address</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        labelHidden={false}
        hasSearchIcon={false}
        isRequired={true}
        isReadOnly={false}
        value={endAddress}
        renderOption={(option, value) => {
          return <HighlightMatch query={value}>{option?.label}</HighlightMatch>;
        }}
        optionFilter={(option, value) => {
          return option?.label?.includes('');
        }}
        onClear={() => {
          setEndAddress('');
        }}
        onSelect={(e) => {
          let value = e.label;
          if (errors.endAddress?.hasError) {
            runValidationTasks("endAddress", value);
          }
          setEndAddress(value);
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress: value,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.endAddress ?? value;
          }
          if (errors.endAddress?.hasError) {
            runValidationTasks("endAddress", value);
          }
          setEndAddress(value);
        }}
        onBlur={() => {
          runValidationTasks("endAddress", endAddress);
          if (startAddress && endAddress) {
            findRoute(startAddress, endAddress)
          }
        }}
        errorMessage={errors.endAddress?.errorMessage}
        hasError={errors.endAddress?.hasError}
        {...getOverrideProps(overrides, "endAddress")}
      ></Autocomplete>
      <TextField
        id="Miles"
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Miles</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText="Commute distance"
        isRequired={true}
        isReadOnly={false}
        placeholder="0.0"
        type="number"
        step="any"
        value={miles}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles: value,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.miles ?? value;
          }
          if (errors.miles?.hasError) {
            runValidationTasks("miles", value);
          }
          setMiles(value);
        }}
        onBlur={() => runValidationTasks("miles", miles)}
        errorMessage={errors.miles?.errorMessage}
        hasError={errors.miles?.hasError}
        {...getOverrideProps(overrides, "miles")}
      ></TextField>
      <TextField
        label="Odometer start"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={odometerStart}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart: value,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.odometerStart ?? value;
          }
          if (errors.odometerStart?.hasError) {
            runValidationTasks("odometerStart", value);
          }
          setOdometerStart(value);
        }}
        onBlur={() => { 
          if (odometerEnd && odometerStart > odometerEnd) {
            setErrors((errors) => ({ ...errors, ["odometerStart"]: {hasError: true, errorMessage: "Odometer start must be less than odometer end"} }));
          }
          else {
            runValidationTasks("odometerStart", odometerStart);
          }
        }}
        errorMessage={errors.odometerStart?.errorMessage}
        hasError={errors.odometerStart?.hasError}
        {...getOverrideProps(overrides, "odometerStart")}
      ></TextField>
      <TextField
        label="Odometer end"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={odometerEnd}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd: value,
              miles,
              parking,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.odometerEnd ?? value;
          }
          if (errors.odometerEnd?.hasError) {
            runValidationTasks("odometerEnd", value);
          }
          setOdometerEnd(value);
        }}
        onBlur={() => { 
          if (odometerStart && odometerStart > odometerEnd) {
            setErrors((errors) => ({ ...errors, ["odometerEnd"]: {hasError: true, errorMessage: "Odometer end must be greater than odometer start"} }));
          }
          else {
            runValidationTasks("odometerEnd", odometerEnd);
          }
        }}
        errorMessage={errors.odometerEnd?.errorMessage}
        hasError={errors.odometerEnd?.hasError}
        {...getOverrideProps(overrides, "odometerEnd")}
      ></TextField>
      <TextField
        label="Parking"
        descriptiveText="Parking costs"
        isRequired={false}
        isReadOnly={false}
        placeholder="$0.00"
        type="number"
        step="any"
        value={parking}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking: value,
              tolls,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.parking ?? value;
          }
          if (errors.parking?.hasError) {
            runValidationTasks("parking", value);
          }
          setParking(value);
        }}
        onBlur={() => runValidationTasks("parking", parking)}
        errorMessage={errors.parking?.errorMessage}
        hasError={errors.parking?.hasError}
        {...getOverrideProps(overrides, "parking")}
      ></TextField>
      <TextField
        label="Tolls"
        isRequired={false}
        isReadOnly={false}
        placeholder="$0.00"
        type="number"
        step="any"
        value={tolls}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls: value,
              total,
              purpose,
              roundtrip,
            };
            const result = onChange(modelFields);
            value = result?.tolls ?? value;
          }
          if (errors.tolls?.hasError) {
            runValidationTasks("tolls", value);
          }
          setTolls(value);
        }}
        onBlur={() => runValidationTasks("tolls", tolls)}
        errorMessage={errors.tolls?.errorMessage}
        hasError={errors.tolls?.hasError}
        {...getOverrideProps(overrides, "tolls")}
      ></TextField>
      {/*
      <TextField
        label="Total"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total: value,
              purpose,
            };
            const result = onChange(modelFields);
            value = result?.total ?? value;
          }
          if (errors.total?.hasError) {
            runValidationTasks("total", value);
          }
          setTotal(value);
        }}
        onBlur={() => runValidationTasks("total", total)}
        errorMessage={errors.total?.errorMessage}
        hasError={errors.total?.hasError}
        {...getOverrideProps(overrides, "total")}
      ></TextField>
      */}
      <TextField
        label="Purpose of trip"
        descriptiveText="What was this commute for?"
        isRequired={false}
        isReadOnly={false}
        value={purpose}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              date,
              CarData,
              chargeCode,
              startAddress,
              endAddress,
              odometerStart,
              odometerEnd,
              miles,
              parking,
              tolls,
              total,
              purpose: value,
            };
            const result = onChange(modelFields);
            value = result?.purpose ?? value;
          }
          if (errors.purpose?.hasError) {
            runValidationTasks("purpose", value);
          }
          setPurpose(value);
        }}
        onBlur={() => runValidationTasks("purpose", purpose)}
        errorMessage={errors.purpose?.errorMessage}
        hasError={errors.purpose?.hasError}
        {...getOverrideProps(overrides, "purpose")}
      ></TextField>
      
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Cancel"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
            {...getOverrideProps(overrides, "CancelButton")}
          ></Button>
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}