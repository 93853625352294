import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";

export default function UserCarTotalsEntry(props) {
  const { carTotals, overrides, ...rest } = props;
  
  return (
    <Flex
      gap="6px"
      direction="row"
      width="100%" // Ensure the Flex container uses full width
      height="auto" // Adjust height to content
      justifyContent="flex-start"
      alignItems="center" // Center items vertically
      overflow="hidden"
      position="relative"
      padding="9px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "TableEntry")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        width="auto" // Let content dictate width
        minWidth="150px" // Minimum width to ensure space
        padding="0px"
        whiteSpace="nowrap" // Prevent text wrapping
        children={carTotals?.name}
        {...getOverrideProps(overrides, "Car")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="right"
        display="block"
        width="180px" // Fixed width for this column
        padding="0px"
        whiteSpace="nowrap" // Prevent text wrapping
        children={carTotals?.monthlyTotal}
        {...getOverrideProps(overrides, "Current Monthly Miles")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="right"
        display="block"
        width="350px" // Fixed width for this column
        padding="0px"
        whiteSpace="nowrap" // Prevent text wrapping
        children={carTotals?.prevMonthTotal}
        {...getOverrideProps(overrides, "Previous Monthly Miles")}
      ></Text>
    </Flex>
  );
}
