import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { DataStore } from "aws-amplify";
import { Expense } from "../models";
import { Flex, Text, View, Menu, MenuItem } from "@aws-amplify/ui-react";

export default function CustomExpenseTableEntry(props) {
  const {expenseData, openEditDialog, overrides, ...rest } = props;
  return (
    <View
      width="1179px"
      height="52px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ExpenseTableEntry")}
      {...rest}
    >
      <Flex
        gap="22px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="6px"
        left="9px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 419")}
      >
        <Menu size="small">
        <MenuItem
        onClick={() => {openEditDialog(expenseData?.usersID, expenseData?.id);
        }}>
          Edit Entry
        </MenuItem>
        <MenuItem
        onClick={async () => {
          try {
            await DataStore.delete(Expense, expenseData?.id);
          } catch (err) {
            console.error(err)
          }
        }}>
          Delete Entry
        </MenuItem>
      </Menu>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="86px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={expenseData?.Date}
          {...getOverrideProps(overrides, "Date")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="127px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={expenseData?.Item}
          {...getOverrideProps(overrides, "Item")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="209px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={expenseData?.Comments}
          {...getOverrideProps(overrides, "Comments")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="78px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${expenseData?.Cost ? expenseData.Cost.toFixed(2): '0.00'}`}
          {...getOverrideProps(overrides, "Cost")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="80px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={expenseData?.Mileage}
          {...getOverrideProps(overrides, "Mileage")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="59px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${expenseData?.Total ? expenseData.Total.toFixed(2): '0.00'}`}
          {...getOverrideProps(overrides, "Total")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="53px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={expenseData?.Billable ? "Yes": "No"}
          {...getOverrideProps(overrides, "Billable")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="95px"
          height="20px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${expenseData?.BillableTotal ? expenseData.BillableTotal.toFixed(2): '0.00'}`}
          {...getOverrideProps(overrides, "Billable Total")}
        ></Text>
      </Flex>
    </View>
  );
}
