import React from "react";
import  CreateExpenseForm  from "./CreateExpenseForm"
import { SelectField } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { Users, Mileage } from "../models";

async function getUsers() {
    const users = await DataStore.query(Users);
    console.log(users);
    return users;
  }

async function getMileage() {
  console.log("Querying Mileage")
  const mileage = await DataStore.query(Mileage);
  return mileage;
}

export class CustomExpenseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedFilterUser: null, dropdownOptions: [{value: "all", label: "All Users"}]}
        getMileage().then((response) => {
          this.setState({mileage: response});
        });

        getUsers().then((usersDataStore) => {
            var dropdownOptions = [];
            for (let x in usersDataStore) {
              // remove users with null names from list:
              if (usersDataStore[x].last_name != null){
                if (usersDataStore[x].owner == null){
                  dropdownOptions.push({ value:usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
                } else {
                  dropdownOptions.push({ value:usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
                }  
              }
              
            }
            this.setState({dropdownOptions: dropdownOptions})
            if (props.userID == null){
              this.setState({selectedFilterUser: dropdownOptions[0].value})
            } else {
              this.setState({selectedFilterUser: props.userID})
            }
            
          })
    }

    userSelectField(adminView){
        if(!adminView){
          return null
        }
        return (
            <SelectField
                label="Assign to User:"
                value={this.state.selectedFilterUser}
                onChange={(e) => {
                    if (e.target.value === "all"){
                    this.setState({selectedFilterUser:null})
                    } else {
                    this.setState({selectedFilterUser: e.target.value})
                    }
                
                }}>
                {this.state.dropdownOptions.map(({value, label}, index) => <option key={value} value={value}>{label}</option>)}
            </SelectField>);
    }

    render() {
        return (
            <div>
                <div style={({paddingLeft:"2vw", paddingRight:"2vw"})}>
                    {this.userSelectField(this.props.adminView)}
                </div>
                <CreateExpenseForm
                  onSubmit={(fields) => {
                    var userId = this.props.userID;
                    if (this.state.selectedFilterUser) {
                    userId = this.state.selectedFilterUser;
                    }
                    const year = parseInt(fields.Date.slice(0,4));
                    console.log("Year:", year);
                    const currMileage = this.state.mileage.filter(mileage => mileage.year === year)[0];
                    const mileageRate = currMileage.rate;
                    if(fields.Mileage === ""){
                      fields.Mileage = 0;
                    }
                    fields.Total = Math.ceil(((mileageRate * fields.Mileage) + fields.Cost ) * 100) / 100
                    if(fields.Billable){
                      console.log("Billable")
                      fields.BillableTotal = fields.Total;
                    }
                    else {
                      fields.BillableTotal = "";
                    }
                    
                    const updatedFields = Object.assign(fields, {usersID: userId});
                    return updatedFields;
                  }}
                  onSuccess={this.props.handleClose}
                  onCancel={this.props.handleClose}
                />
            </div>
        )
    }
}