import { Button } from "@aws-amplify/ui-react";
import * as React from "react";
import { signOutClear } from "../functions/SignOutClear";

export default function Header(props) {
    const { user, signOut, overrideItems, overrides, ...rest } = props;
    return (
        <div style={({display: "flex", marginLeft: "1vw", marginBottom:"2vh", marginTop: "1vh", marginRight:"1vh", justifyContent: "space-between"})}>
            Welcome, {user.attributes.given_name} {user.attributes.family_name}
            <Button size="small" onClick={() => {
                console.log("clearing and signing out");
                sessionStorage.clear()
                signOutClear(signOut);
            }}>Sign out</Button>
        </div>
    )
}