import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { CommuteEntries } from "../models";

function calculateSumFromCommutes(commuteList) {
  // return sum of zero for empty list
  if (commuteList == null || commuteList.length === 0) {
    return 0;
  }
  // return the only total if there's only one entry
  if (commuteList.length === 1) {
    return commuteList[0].total;
  }

  // calculate the sum of the totals of the given list of commutes
  let result = commuteList.reduce(function(a, b){
    let totalA = a;
    let totalB = b.total;
    if (typeof(a) != 'number'){
      totalA = a.total;
    }
    return totalA + totalB;
  });
  return result.toFixed(2);
}

export default function CustomTotalsTableEntry(props) {
  const { users, monthFilter, totalRowAppend, overrides, ...rest } = props;
  const [ commuteInfo, setCommuteInfo ] = React.useState();
  const [ commuteGA, setCommuteGA ] = React.useState();
  const [ commuteOverhead, setCommuteOverhead ] = React.useState();
  const [ commuteUnallowable, setCommuteUnallowable ] = React.useState();
  const [ commuteTotal, setCommuteTotal ] = React.useState();
  const [ usedOwnerID, setUsedOwnerID ] = React.useState();

  React.useEffect(() => {
    async function setCommuteInfoFromID(userID){
      const results = await DataStore.query(CommuteEntries, c => c.usersID.eq(userID));
      setCommuteInfo(results.filter(commute => commute.date?.includes(monthFilter.toISOString().substring(0,8))))
    }
    // check if the correct user ID is in the Owner field, if not use the ID field
    if(users?.owner){
      setCommuteInfoFromID(users?.owner)
      setUsedOwnerID(users?.owner)
    } else {
      setCommuteInfoFromID(users?.id)
      setUsedOwnerID(users?.id)
    }
  }, [users, monthFilter])

  React.useEffect(() => {
    // filter out each of the charge codes to calculate their totals
    if(commuteInfo != null) {
      const totalGA = calculateSumFromCommutes(commuteInfo.filter(commute => commute.chargeCode === "GA"));
      const totalOverhead = calculateSumFromCommutes(commuteInfo.filter(commute => commute.chargeCode === "OVERHEAD"));
      const totalUnallowable = calculateSumFromCommutes(commuteInfo.filter(commute => commute.chargeCode === "UNALLOWABLE"));
      const finalTotal = calculateSumFromCommutes(commuteInfo);
      setCommuteGA(totalGA);
      setCommuteOverhead(totalOverhead);
      setCommuteUnallowable(totalUnallowable);
      setCommuteTotal(finalTotal);

      if(totalRowAppend != null) {
        totalRowAppend({user: usedOwnerID, ga: totalGA, overhead: totalOverhead, unallowable: totalUnallowable, total: finalTotal});
      }
    }
  }, [commuteInfo])
  
  return (
    <Flex
      gap="6px"
      direction="row"
      width="948px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="9px 10px 9px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "TotalsTableEntry")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={users?.last_name}
        {...getOverrideProps(overrides, "name")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"$"}${commuteGA}`}
        {...getOverrideProps(overrides, "ga")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"$"}${commuteOverhead}`}
        {...getOverrideProps(overrides, "overhead")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"$"}${commuteUnallowable}`}
        {...getOverrideProps(overrides, "unallowable")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"$"}${commuteTotal}`}
        {...getOverrideProps(overrides, "total")}
      ></Text>
    </Flex>
  );
}