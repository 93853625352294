// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ChargeCodes = {
  "GA": "GA",
  "OVERHEAD": "OVERHEAD",
  "UNALLOWABLE": "UNALLOWABLE"
};

const { ExpenseAdminUsage, Expense, Mileage, Users, CarData, CommuteEntries } = initSchema(schema);

export {
  ExpenseAdminUsage,
  Expense,
  Mileage,
  Users,
  CarData,
  CommuteEntries,
  ChargeCodes
};