/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Text, View } from "@aws-amplify/ui-react";
export default function ExpenseTableHeader(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="1047px"
      height="47px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ExpenseTableHeader")}
      {...rest}
    >
      <Divider
        width="964.06px"
        height="21px"
        position="absolute"
        top="19.51px"
        left="53.06px"
        transformOrigin="top left"
        transform="rotate(0.18deg)"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider")}
      ></Divider>
      <View
        width="1000px"
        height="20px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="11px"
        left="62px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 423")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="9px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Date"
          {...getOverrideProps(overrides, "Date")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="121px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Item"
          {...getOverrideProps(overrides, "Item")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="270px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Comments"
          {...getOverrideProps(overrides, "Comments")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="497px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Cost"
          {...getOverrideProps(overrides, "Cost")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="589px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Mileage"
          {...getOverrideProps(overrides, "Mileage")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="694px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Total"
          {...getOverrideProps(overrides, "Total")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="772px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Billable"
          {...getOverrideProps(overrides, "Billable")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="849px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Billable Total"
          {...getOverrideProps(overrides, "Billable Total")}
        ></Text>
      </View>
    </View>
  );
}
