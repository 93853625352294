import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@aws-amplify/ui-react/styles.css';

import { Amplify, AuthModeStrategyType, DataStore } from 'aws-amplify';
import config from './aws-exports';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import awsConfig from './aws-exports';

DataStore.configure();
Amplify.configure({
  ...config,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
  });

const theme = {
  name: 'thisisatheme',
  tokens: {
    colors: {
      background: {
        primary: {value: 'tan'}
      }
    }
  }
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const isConstantAdvancement = Boolean(window.location.hostname.includes('constantadvancement'));
const isDevAppMigration = Boolean(window.location.hostname.includes('dev-app-migration'));

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  appMigrationSignIn,
  constantAdvancementSignIn,
  devAppMigrationSignIn
] = awsConfig.oauth.redirectSignIn.split(',');

const [
  localRedirectSignOut,
  appMigrationSignOut,
  constantAdvancementSignOut,
  devAppMigrationSignOut
] = awsConfig.oauth.redirectSignOut.split(',');

const productionRedirectSignIn = isConstantAdvancement ? constantAdvancementSignIn : devAppMigrationSignIn;
const productionRedirectSignOut = isConstantAdvancement ? constantAdvancementSignOut : devAppMigrationSignOut;

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AmplifyProvider theme={theme}>
    <ProSidebarProvider> 
      <App/>
    </ProSidebarProvider>
  </AmplifyProvider>
);
