import * as React from "react";
import { CommuteEntries, CarData } from "../models";
import { SortDirection } from "@aws-amplify/datastore";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import { Collection } from "@aws-amplify/ui-react";

import UserCarTotalsEntry from "./UserCarTotalsEntry";
import UserTotalsHeader from "./UserTotalsHeader";

export default function UserCarTotalsEntryCollection(props) {
    const { userID, monthFilter, selectedFilterUser, overrideItems, overrides, ...rest } = props;
    const itemsPagination = { sort: (s) => s.date(SortDirection.ASCENDING) };
    const itemsDataStore = useDataStoreBinding({
        type: "collection",
        model: CommuteEntries,
        pagination: itemsPagination,
    }).items;
    const carsPagination = { sort: (s) => s.year(SortDirection.ASCENDING) };
    const carDataStore = useDataStoreBinding({
        type: "collection",
        model: CarData,
        pagination: carsPagination,
    }).items;

    const [userCarDataStore, setUserCarDataStore] = React.useState();
    const [userCommutesDataStore, setUserCommutesDataStore] = React.useState();
    const [lastMonth, setLastMonth] = React.useState();

    React.useEffect(() => {
        var lastMonth = new Date(monthFilter);
        lastMonth.setDate(1)
        lastMonth.setMonth(lastMonth.getMonth()-1)
        setLastMonth(lastMonth);
    }, [monthFilter])

    React.useEffect(() => {
        const filterUser = selectedFilterUser ? selectedFilterUser: "";
        setUserCarDataStore(carDataStore.filter(item => item.usersID.includes(filterUser)));
        setUserCommutesDataStore(itemsDataStore.filter(item => item.usersID.includes(filterUser)));
    }, [selectedFilterUser, carDataStore, itemsDataStore])

    

    var carTotals = [];
    for (let i = 0; i < userCarDataStore?.length; i++) {
        const car = userCarDataStore[i];
        var carCommutesDataStore = userCommutesDataStore.filter(item => item.cardataID===car.id &
            new Date(item.date).getFullYear()===monthFilter.getFullYear() &
            new Date(item.date).getUTCMonth()===monthFilter.getUTCMonth())
        const totalMiles = carCommutesDataStore.reduce(
            (accumulator, currentCommute) => accumulator + currentCommute.miles,
            0
        );
        carCommutesDataStore = userCommutesDataStore.filter(item => item.cardataID===car.id &
            new Date(item.date).getFullYear()===lastMonth.getFullYear() &
            new Date(item.date).getUTCMonth()===lastMonth.getUTCMonth())
        const lastMonthMiles = carCommutesDataStore.reduce(
            (accumulator, currentCommute) => accumulator + currentCommute.miles,
            0
        );
        carTotals.push({id: car.id, name: car.carName, monthlyTotal: totalMiles, prevMonthTotal: lastMonthMiles})
    }
    return (
        <div display="flex" style={({flexDirection:"column", alignItems:"flex-start", width:"100%"})}>
            <UserTotalsHeader today={monthFilter} lastMonth={lastMonth}></UserTotalsHeader>
            <Collection
            type="list"
            isPaginated={true}
            searchPlaceholder="Search..."
            itemsPerPage={10}
            direction="column"
            alignItems="stretch"
            justifyContent="left"
            items={carTotals || []}
            {...rest}
            {...getOverrideProps(overrides, "TableEntryCollection")}
            >
            {(item, index) => (
                <UserCarTotalsEntry
                carTotals={item}
                key={item.id}
                {...(overrideItems && overrideItems({ item, index }))}
                ></UserCarTotalsEntry>
            )}
            </Collection>
        </div>
    )
}