import * as React from "react";
import { Users } from "../models";
import {
  getOverrideProps,
  useDataStoreDeleteAction,
} from "@aws-amplify/ui-react/internal";
import { schema } from "../models/schema";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import awsmobile from "../aws-exports";

export default function CustomEmployeeEntry(props) {
  const { users, overrides, ...rest } = props;
  const removeUserFromDynamo = useDataStoreDeleteAction({
    model: Users,
    id: users?.id,
    schema: schema,
  });
  const getParams = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: users.username
    };
  var isAdmin = false;
  const [adminButtonText, setAdminButton] = React.useState("Add Admin")
  var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();
  cognitoidentityserviceprovider.adminListGroupsForUser(getParams, function(err, data) {
    if (err) {
        console.log(err, err.stack); // an error occurred
    }else {
        const groups = data.Groups;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].GroupName == "Admin") {
                isAdmin = true
                setAdminButton("Remove Admin")
            }
        }
    }
  });

  return (
    <Flex
      gap="26px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="17px 19px 17px 19px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EmployeeEntry")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${users?.first_name}${" "}${users?.last_name}`}
        {...getOverrideProps(overrides, "EmployeeName")}
      ></Text>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        size="small"
        isDisabled={false}
        variation="primary"
        children="Remove User"
        onClick={() => {
            if (window.confirm("Are you sure you want to remove " + `${users?.first_name}${" "}${users?.last_name}` +"?")) {
                removeUserFromDynamo();
                cognitoidentityserviceprovider.adminDeleteUser(getParams, function(err, data) {
                if(err) {
                    console.log(err, err.stack); //an error occurred
                    }
                });
                } else {
              }
            }}
        {...getOverrideProps(overrides, "Button394937")}
      ></Button>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        size="small"
        isDisabled={false}
        variation="primary"
        children = {adminButtonText}
        onClick={() => {
            const getParams = {
                UserPoolId: awsmobile.aws_user_pools_id,
                Username: users.username
            };
            if (isAdmin) {
                const params = {GroupName: "Admin", UserPoolId: awsmobile.aws_user_pools_id, Username: users.username}
                cognitoidentityserviceprovider.adminRemoveUserFromGroup(params, function(err, data) {
                    if (err) {
                        console.log(err, err.stack)
                    } else {
                        isAdmin = false;
                        setAdminButton("Add Admin")
                    }
                })
                
            } else {
                const params= {GroupName: "Admin", UserPoolId: awsmobile.aws_user_pools_id, Username: users.username}
                cognitoidentityserviceprovider.adminAddUserToGroup(params, function(err, data) {
                    if (err) {
                        console.log(err, err.stack)
                    } else {
                        isAdmin = true;
                        setAdminButton("Remove Admin")
                    }
                })
            }
        }}
        {...getOverrideProps(overrides, "Button394941")}
      ></Button>
    </Flex>
  );
}
