import { DataStore } from "aws-amplify";
import React from "react";
import { Expense, Mileage } from "../models";
import ExpenseUpdateForm  from "../components/CustomExpenseUpdateForm"

async function getExpense(expenseid) {
    const expense = await DataStore.query(Expense, expenseid);
    console.log(expenseid)
    return expense;
}

async function getMileage() {
    console.log("Querying Mileage")
    const mileage = await DataStore.query(Mileage);
    return mileage;
  }

export class UpdateExpenseForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            expense: {},
            mileage: [],
            expenseLoaded: false
        }
        getMileage().then((response) => {
            this.setState({mileage: response});
          });
    }

    componentDidMount() {
        getExpense(this.props.expenseid).then((response) => {
            this.setState({
                expense: response,
                expenseLoaded: true
            })
            console.log(response)
        })
    }

    render() {
        if(this.state.expenseLoaded){
            return (
                <ExpenseUpdateForm 
                    expense={this.state.expense}
                    onSubmit= {(fields) => {
                    //   const updatedFields = Object.assign(fields, {usersID: this.props.userID});
                    //   return updatedFields;
                    // putting lines 50-71 ensured you could change the billable radio button and reflect changes to total & billable total accordingly
                    var userId = this.props.userID;
                    if (this.state.selectedFilterUser) {
                    userId = this.state.selectedFilterUser;
                    }
                    const year = parseInt(fields.Date.slice(0,4));
                    console.log("Year:", year);
                    const currMileage = this.state.mileage.filter(mileage => mileage.year === year)[0];
                    const mileageRate = currMileage.rate;
                    if(fields.Mileage === ""){
                      fields.Mileage = 0;
                    }
                    fields.Total = Math.ceil(((mileageRate * fields.Mileage) + fields.Cost ) * 100) / 100
                    if(fields.Billable){
                      console.log("Billable")
                      fields.BillableTotal = fields.Total;
                    }
                    else {
                      fields.BillableTotal = "";
                    }
                    
                    const updatedFields = Object.assign(fields, {usersID: userId});
                    return updatedFields;
                  }}
                      onSuccess= {() => {
                        console.log("Successful upload")
                        if (this.props.closeDialog){
                            this.props.closeDialog();
                          } else {
                            this.props.navHook("/Expensing");
                          }
                      }}
                      onError= {(error,msg) => {
                        console.log("error")
                        console.log(error)
                        console.log(msg)
                      }}>
                        

                </ExpenseUpdateForm>
            )
        } else {
            return (
                <div>Loading...</div>
            )
        }
        
    }
}