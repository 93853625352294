import React from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { MenuOutlined, DirectionsCarOutlined, AddRoadOutlined, SummarizeOutlined, AccountBalanceOutlined } from '@mui/icons-material';
import { Outlet, Link } from 'react-router-dom';

function Layout({user}) {
    const { collapseSidebar, toggleSidebar} = useProSidebar();
    const toggle = () => {
      toggleSidebar();
      collapseSidebar();
    }
    let adminView = false;
    if(user.signInUserSession.accessToken.payload["cognito:groups"]){
      if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin")){
        adminView = true;
      }
    }

    function menuList(adminView){
      if (adminView) {
        return (
          <Menu>
            <MenuItem component={<Link to="/"/>} icon={<MenuOutlined/>}>Home</MenuItem>
            <MenuItem component={<Link to="/Cars"/>} icon={<DirectionsCarOutlined/>}>Cars</MenuItem>
            <MenuItem component={<Link to="/Expensing"/>} icon={<AccountBalanceOutlined/>}>Expensing</MenuItem>
            <MenuItem component={<Link to="/AdminView"/>} icon={<SummarizeOutlined/>}>Admin View</MenuItem>
          </Menu>
        )
      }
      return (
        <Menu>
          <MenuItem component={<Link to="/"/>} icon={<MenuOutlined/>}>Home</MenuItem>
          <MenuItem component={<Link to="/Cars"/>} icon={<DirectionsCarOutlined/>}>Cars</MenuItem>
          <MenuItem component={<Link to="/Expensing"/>} icon={<AccountBalanceOutlined/>}>Expensing</MenuItem>
          <MenuItem component={<Link to="/MyTotals"/>} icon={<AddRoadOutlined/>}>My Totals</MenuItem>
        </Menu>
      )
    }
    return (
        <div style={({display:"flex", height:"100vh"})}>
            <Sidebar defaultCollapsed="true" rtl={false} onClick={() => {toggle();}}>
                {menuList(adminView)}
            </Sidebar>
            <div style={({flexGrow:100})}>
                <Outlet />
            </div>
            
        </div>
    )
}

export default Layout;