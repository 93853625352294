import React from 'react';
import { Button, Flex } from '@aws-amplify/ui-react';

import CustomCarTableEntryCollection from '../../components/CustomCarTableEntryCollection';
import { checkUserTable } from '../../functions/UserTablePopulate';
import Header from '../../components/Header';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { CustomCarForm } from '../../components/CustomCarForm';

function Cars({ signOut, user}) {
  const [open, setOpen] = React.useState(false);

  let adminView = false;
  if(user.signInUserSession.accessToken.payload["cognito:groups"]){
    if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin")){
      adminView = true;
    }
  }
  checkUserTable(user);

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='App' style={({display: "flex"})}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>New Car</DialogTitle>
          <DialogContent>
            <Flex direction="row" alignItems="flex-start">
              <Flex direction="column">
                <CustomCarForm userID={user.attributes.sub} handleClose={handleClose} adminView={adminView}/>
              </Flex>
            </Flex>
          </DialogContent>
        </Dialog>
      <div className='content'>
      <Header user={user} signOut={signOut}/>
      <Flex direction="column" alignItems="flex-start" style={{marginLeft: "1em"}}>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Add new car"
          onClick={() => {
            handleClickOpen();
          }}
        ></Button>
        <CustomCarTableEntryCollection userID={user.attributes.sub} adminView={adminView}></CustomCarTableEntryCollection>
      </Flex>
      </div>
    </div>
  )
}

export default Cars;
