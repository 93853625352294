import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Text, View } from "@aws-amplify/ui-react";
export default function UserTotalsHeader(props) {
  const { overrides, today, lastMonth, ...rest } = props;

  return (
    <Flex
      gap="0"
      direction="column"
      width="inherit"
      height="unset"
      justifyContent="left"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="0px 10px 0px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CommuteTableHeader")}
    >
      <Flex
        gap="0px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start" // Changed from 'left' to 'flex-start'
        shrink="0"
        alignSelf="auto"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 28")}
      >
        <View
          width="0px"
          height="unset"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,0)"
          {...getOverrideProps(overrides, "Rectangle 1164")}
        ></View>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="0px"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="0.43"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Car"
          {...getOverrideProps(overrides, "Car")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="170px"
          height="unset"
          gap="unset"
          alignItems="auto"
          grow="0.5"
          shrink="1"
          basis="auto"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={
            "Current Monthly Miles (" +
            today?.toLocaleString("default", { month: "long" }) +
            " " +
            today?.getFullYear() +
            ")"
          }
          {...getOverrideProps(overrides, "Current Monthly Miles")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="right"
          display="block"
          direction="column"
          justifyContent="unset"
          width="690px"
          height="unset"
          gap="unset"
          alignItems="auto"
          grow="0.5"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={
            "Previous Monthly Miles (" +
            lastMonth?.toLocaleString("default", { month: "long" }) +
            " " +
            lastMonth?.getFullYear() +
            ")"
          }
          {...getOverrideProps(overrides, "Previous Monthly Miles")}
        ></Text>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider")}
      ></Divider>
    </Flex>
  );
}
