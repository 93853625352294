/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kerjte7d6fa2dnscyab2tyqv4m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:8a91ab47-8118-4f9e-a8c1-f9c429627f13",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_r5Hg5sD5C",
    "aws_user_pools_web_client_id": "nintjhqd9qjqrl4pa6dketo3b",
    "oauth": {
        "domain": "commuteappc073fff3-c073fff3-backup.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://app-migration.d1umghjv5i11mr.amplifyapp.com/,https://www.commute.constantadvancement.com/,https://dev-app-migration.d1umghjv5i11mr.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://app-migration.d1umghjv5i11mr.amplifyapp.com/,https://www.commute.constantadvancement.com/,https://dev-app-migration.d1umghjv5i11mr.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "mapacd85fc0-backup": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "mapacd85fc0-backup"
            },
            "search_indices": {
                "items": [
                    "placeIndex0ee05ebc-backup"
                ],
                "default": "placeIndex0ee05ebc-backup"
            }
        }
    }
};


export default awsmobile;
