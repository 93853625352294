/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { CarData } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function CarDataUpdateForm(props) {
  const {
    id: idProp,
    carData: carDataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    carName: "",
    licensePlate: "",
    year: "",
    yearStartMileage: "",
    yearEndMileage: "",
    make: "",
    model: "",
  };
  const [carName, setCarName] = React.useState(initialValues.carName);
  const [licensePlate, setLicensePlate] = React.useState(
    initialValues.licensePlate
  );
  const [year, setYear] = React.useState(initialValues.year);
  const [yearStartMileage, setYearStartMileage] = React.useState(
    initialValues.yearStartMileage
  );
  const [yearEndMileage, setYearEndMileage] = React.useState(
    initialValues.yearEndMileage
  );
  const [make, setMake] = React.useState(initialValues.make);
  const [model, setModel] = React.useState(initialValues.model);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = carDataRecord
      ? { ...initialValues, ...carDataRecord }
      : initialValues;
    setCarName(cleanValues.carName);
    setLicensePlate(cleanValues.licensePlate);
    setYear(cleanValues.year);
    setYearStartMileage(cleanValues.yearStartMileage);
    setYearEndMileage(cleanValues.yearEndMileage);
    setMake(cleanValues.make);
    setModel(cleanValues.model);
    setErrors({});
  };
  const [carDataRecord, setCarDataRecord] = React.useState(carDataModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(CarData, idProp)
        : carDataModelProp;
      setCarDataRecord(record);
    };
    queryData();
  }, [idProp, carDataModelProp]);
  React.useEffect(resetStateValues, [carDataRecord]);
  const validations = {
    carName: [],
    licensePlate: [],
    year: [],
    yearStartMileage: [],
    yearEndMileage: [],
    make: [],
    model: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          carName,
          licensePlate,
          year,
          yearStartMileage,
          yearEndMileage,
          make,
          model,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(
            CarData.copyOf(carDataRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CarDataUpdateForm")}
      {...rest}
    >
      <TextField
        label="Car name"
        isRequired={false}
        isReadOnly={false}
        value={carName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName: value,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage,
              make,
              model,
            };
            const result = onChange(modelFields);
            value = result?.carName ?? value;
          }
          if (errors.carName?.hasError) {
            runValidationTasks("carName", value);
          }
          setCarName(value);
        }}
        onBlur={() => runValidationTasks("carName", carName)}
        errorMessage={errors.carName?.errorMessage}
        hasError={errors.carName?.hasError}
        {...getOverrideProps(overrides, "carName")}
      ></TextField>
      <TextField
        label="License plate"
        isRequired={false}
        isReadOnly={false}
        value={licensePlate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName,
              licensePlate: value,
              year,
              yearStartMileage,
              yearEndMileage,
              make,
              model,
            };
            const result = onChange(modelFields);
            value = result?.licensePlate ?? value;
          }
          if (errors.licensePlate?.hasError) {
            runValidationTasks("licensePlate", value);
          }
          setLicensePlate(value);
        }}
        onBlur={() => runValidationTasks("licensePlate", licensePlate)}
        errorMessage={errors.licensePlate?.errorMessage}
        hasError={errors.licensePlate?.hasError}
        {...getOverrideProps(overrides, "licensePlate")}
      ></TextField>
      <TextField
        label="Year"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={year}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              carName,
              licensePlate,
              year: value,
              yearStartMileage,
              yearEndMileage,
              make,
              model,
            };
            const result = onChange(modelFields);
            value = result?.year ?? value;
          }
          if (errors.year?.hasError) {
            runValidationTasks("year", value);
          }
          setYear(value);
        }}
        onBlur={() => runValidationTasks("year", year)}
        errorMessage={errors.year?.errorMessage}
        hasError={errors.year?.hasError}
        {...getOverrideProps(overrides, "year")}
      ></TextField>
      <TextField
        label="Year start mileage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={yearStartMileage}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              carName,
              licensePlate,
              year,
              yearStartMileage: value,
              yearEndMileage,
              make,
              model,
            };
            const result = onChange(modelFields);
            value = result?.yearStartMileage ?? value;
          }
          if (errors.yearStartMileage?.hasError) {
            runValidationTasks("yearStartMileage", value);
          }
          setYearStartMileage(value);
        }}
        onBlur={() => runValidationTasks("yearStartMileage", yearStartMileage)}
        errorMessage={errors.yearStartMileage?.errorMessage}
        hasError={errors.yearStartMileage?.hasError}
        {...getOverrideProps(overrides, "yearStartMileage")}
      ></TextField>
      <TextField
        label="Year end mileage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={yearEndMileage}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              carName,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage: value,
              make,
              model,
            };
            const result = onChange(modelFields);
            value = result?.yearEndMileage ?? value;
          }
          if (errors.yearEndMileage?.hasError) {
            runValidationTasks("yearEndMileage", value);
          }
          setYearEndMileage(value);
        }}
        onBlur={() => runValidationTasks("yearEndMileage", yearEndMileage)}
        errorMessage={errors.yearEndMileage?.errorMessage}
        hasError={errors.yearEndMileage?.hasError}
        {...getOverrideProps(overrides, "yearEndMileage")}
      ></TextField>
      <TextField
        label="Make"
        isRequired={false}
        isReadOnly={false}
        value={make}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage,
              make: value,
              model,
            };
            const result = onChange(modelFields);
            value = result?.make ?? value;
          }
          if (errors.make?.hasError) {
            runValidationTasks("make", value);
          }
          setMake(value);
        }}
        onBlur={() => runValidationTasks("make", make)}
        errorMessage={errors.make?.errorMessage}
        hasError={errors.make?.hasError}
        {...getOverrideProps(overrides, "make")}
      ></TextField>
      <TextField
        label="Model"
        isRequired={false}
        isReadOnly={false}
        value={model}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage,
              make,
              model: value,
            };
            const result = onChange(modelFields);
            value = result?.model ?? value;
          }
          if (errors.model?.hasError) {
            runValidationTasks("model", value);
          }
          setModel(value);
        }}
        onBlur={() => runValidationTasks("model", model)}
        errorMessage={errors.model?.errorMessage}
        hasError={errors.model?.hasError}
        {...getOverrideProps(overrides, "model")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || carDataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || carDataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
