import React from 'react';
import { Button, Flex } from '@aws-amplify/ui-react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import CustomCommuteTableEntryCollection from '../../components/CustomCommuteTableEntryCollection'
import { checkUserTable } from '../../functions/UserTablePopulate';
import Header from '../../components/Header';
import { CustomCommuteForm } from '../../components/CustomCommuteForm';


function PastEntries({signOut, user}) {
  const [open, setOpen] = React.useState(false);
  const today = new Date();
  let hasVisited = sessionStorage.getItem('washere');
  if (!hasVisited === 'false'){
    sessionStorage.setItem('washere',true)
    hasVisited = false
  } else {
    hasVisited = true
  }
  const [openStart, setOpenStart] = React.useState(today.getMonth()===0 && today.getDay()<11 && !hasVisited);
  const [openEnd, setOpenEnd] = React.useState(today.getMonth()===11 && today.getDay()>20 && !hasVisited);

  const handleCloseStart = () => {
    setOpenStart(false);
  };
  const handleCloseEnd = () => {
    setOpenEnd(false);
  };

  let adminView = false;
  if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
    if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin")) {
      adminView = true;
    }
  }

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };
  
  checkUserTable(user);
  return (
      <div className='App' style={({display: "flex"})}>
        <Dialog open={openStart} onClose={handleCloseStart} maxWidth="lg">
          <DialogTitle>Have you set your cars' year start mileage?</DialogTitle>
          <DialogContent>
            <Flex direction="column">
              <p>Please edit your year start mileage for each car to reflect the new commute year</p>
              <small>This reminder window will display until January 11th</small>
              <Button variation='primary' onClick={() => {
                window.location = window.location+"Cars";
                }}>Go to Cars</Button>
            </Flex>
          </DialogContent>
        </Dialog>
        <Dialog open={openEnd} onClose={handleCloseEnd} maxWidth="lg">
          <DialogTitle>Have you set your cars' year end mileage?</DialogTitle>
          <DialogContent>
            <Flex direction="column">
              <p>Please edit your year end mileage for each car to reflect the end of the commute year</p>
              <small>This reminder window will display for the remainder of December</small>
              <Button variation='primary' onClick={() => {
                window.location = window.location+"Cars";
                }}>Go to Cars</Button>
            </Flex>
          </DialogContent>
        </Dialog>
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>New Commute</DialogTitle>
          <DialogContent>
              <Flex direction="column">
                <CustomCommuteForm userID = {user.attributes.sub} adminView={adminView} closeDialog={handleClose}/>
              </Flex>
          </DialogContent>
        </Dialog>
        <div className='content'>
        <Header user={user} signOut={signOut}/>
        <Flex direction="row" alignItems="flex-start">
          <Flex direction="column" style={{marginLeft: "1em"}}>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Create new commute"
              onClick={() => {
                handleClickOpen();
              }}
            ></Button>
            <h3>Previous Entries</h3> 
          </Flex>
        </Flex>
        <CustomCommuteTableEntryCollection userID = {user.attributes.sub} adminView = {adminView}/> 
        </div>
      </div>
    )
  }
  export default PastEntries;