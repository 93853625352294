import * as React from "react";
import { CarData, ChargeCodes, CommuteEntries } from "../models";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Menu, MenuItem, SelectField, Text, TextField } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default function CustomCommuteTableEntry(props) {
  const { commuteEntries, openDuplicateDialog, openEditDialog, overrides, ...rest } = props;
  const [ tollValue, setTollValue ] = React.useState();
  const [ parkingValue, setParkingValue ] = React.useState();
  let date = new Date(commuteEntries.date);
  let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
  let tzDate = new Date(date.toLocaleString('en-US', { timeZone: "America/New_York" }));
  let offset = utcDate.getTime() - tzDate.getTime();
  date.setTime( date.getTime() + offset );
  const [ dateValue, setDateValue ] = React.useState(date);

  const [ carName, setCarName ] = React.useState(commuteEntries?.carNameString);

  React.useEffect(() => {
    let value = 0;
    if (commuteEntries.tolls != null){
      value = commuteEntries.tolls;
    }
    setTollValue(value.toFixed(2));
  }, [commuteEntries])

  React.useEffect(() => {
    let value = 0;
    if (commuteEntries.parking != null){
      value = commuteEntries.parking;
    }
    setParkingValue(value.toFixed(2));
  }, [commuteEntries])

  React.useEffect(() => {
    async function getModelAndMake() {
      if (!carName) {
        const id = commuteEntries.cardataID;
        const car = await DataStore.query(CarData, id);
        setCarName(`${car.model} ${car.make}`);
      }
    }

    getModelAndMake();
  }, [carName, commuteEntries]);

  async function updateChargeCode(id, newCode){
    const original = await DataStore.query(CommuteEntries, id);
    console.log("original: ", original);
    console.log("update: ", id, newCode);
    let newCodeString = "G&A";
    if (newCode === ChargeCodes.OVERHEAD){
      newCodeString = "Overhead";
    } else if (newCode === ChargeCodes.UNALLOWABLE){
      newCodeString = "Unallowable";
    }
    console.log("New String: ", newCodeString);

    const updatedCommute = await DataStore.save(
      CommuteEntries.copyOf(original, updated => {
        updated.chargeCode = newCode;
        updated.chargeCodeString = newCodeString
      })
    );
    console.log("updated Commute: ", updatedCommute);
  }

  async function updateTolls(id, newToll){
    const original = await DataStore.query(CommuteEntries, id);
    const updatedTolls = await DataStore.save(
      CommuteEntries.copyOf(original, updated => {
        updated.total = Math.ceil((original.total - original.tolls + newToll) * 100)/100;
        updated.tolls = newToll;
      })
    );
  }

  async function updateParking(id, newPark){
    const original = await DataStore.query(CommuteEntries, id);
    const updatedParking = await DataStore.save(
      CommuteEntries.copyOf(original, updated => {
        updated.total = Math.ceil((original.total - original.parking + newPark) * 100)/100;
        updated.parking = newPark;
      })
    );
  }
  
  async function updateDate(id, newDate){
    const original = await DataStore.query(CommuteEntries, id);
    const updatedDate = await DataStore.save(
      CommuteEntries.copyOf(original, updated => {
        updated.date = newDate.toISOString().slice(0, 10);
      })
    );
  }

  return (
    <Flex
      gap="6px"
      direction="row"
      width="1440px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="visible"
      position="relative"
      padding="9px 10px 9px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "TableEntry")}
      {...rest}
    >
      <Menu size="small">
        <MenuItem
        onClick={() => {
          openDuplicateDialog(commuteEntries?.usersID, commuteEntries?.id);
        }}>
          Duplicate Entry
        </MenuItem>
        <MenuItem
        onClick={() => {
          openEditDialog(commuteEntries?.usersID, commuteEntries?.id);
        }}>
          Edit Entry
        </MenuItem>
        <MenuItem
        onClick={async () => {
          try {
            await DataStore.delete(CommuteEntries, commuteEntries?.id);
          } catch (err) {
            console.error(err)
          }
        }}>
          Delete Entry
        </MenuItem>
      </Menu>
      <style>
        {`.date-picker input {
          width: 100px
        }`}
      </style>
      <div style={{}}>
      <DatePicker
        selected={dateValue}
        onChange={(date) => {
          setDateValue(date)
          updateDate(commuteEntries?.id,date)
        }}
        wrapperClassName="date-picker"
      />
      </div>
      <Text
        id="text-car-name"
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carName}
        {...getOverrideProps(overrides, "Car")}
      ></Text>
      <SelectField
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        variation="quiet"
        size="small"
        value={commuteEntries?.chargeCode}
        onChange={(e) => {
          let { value } = e.target;
          
          console.log("onChange ", value);
          console.log("commuteEntries: ", commuteEntries);
          //set the new chargeCode and chargeCodeString
          updateChargeCode(commuteEntries?.id, value);
          
        }}
        {...getOverrideProps(overrides, "chargecode")}
      >
        <option
          children="G&A"
          value="GA"
          {...getOverrideProps(overrides, "chargeCodeoption0")}
        ></option>
        <option
          children="Overhead"
          value="OVERHEAD"
          {...getOverrideProps(overrides, "chargeCodeoption1")}
        ></option>
        <option
          children="Unallowable"
          value="UNALLOWABLE"
          {...getOverrideProps(overrides, "chargeCodeoption2")}
        ></option>
    </SelectField>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={commuteEntries?.startAddress}
        {...getOverrideProps(overrides, "start")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={commuteEntries?.endAddress}
        {...getOverrideProps(overrides, "end")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={commuteEntries?.odometerStart}
        {...getOverrideProps(overrides, "odometerstart")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={commuteEntries?.odometerEnd}
        {...getOverrideProps(overrides, "odometerend")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={commuteEntries?.miles}
        {...getOverrideProps(overrides, "miles")}
      ></Text>
      <Flex 
        alignItems={"center"}
        width="unset"
        basis="0"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"$"}${commuteEntries?.parking ? commuteEntries.parking.toFixed(2): '0.00'}`}
        {...getOverrideProps(overrides, "parking")}
        >
        <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="row"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            size="small"
            children="$"
          ></Text>
        <TextField
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="90px"
          height="unset"
          gap="unset"
          alignItems="unset"
          // grow="0"
          // shrink="0"
          // basis="1"
          position="relative"
          padding="0px 30px 0px 0px"
          whiteSpace="pre-wrap"
          size="small"
          variation="quiet"
          value={parkingValue}
          // {...getOverrideProps(overrides, "tolls")}
          onChange={(e) => {
            let value = e.target.value;
            setParkingValue(value);
          }}
          onBlur={(e) => {
            let inputValue = e.target.value;
            let value = 0;
            if(inputValue.includes("+") || inputValue.includes("-")){
              // split by addition first
              const additions = inputValue.split("+");
              for (let x in additions) {
                // split and calculate subtraction
                const subtractions = additions[x].split("-");
                let addValue = parseFloat(subtractions[0]);
                subtractions.shift();
                for (let y in subtractions){
                  addValue -= parseFloat(subtractions[y]);
                }
                value += addValue;
              }
            } else {
              value = parseFloat(inputValue);
            }

            if(isNaN(value)){
              value = 0;
            }
            if (value < 0){
              value = 0;
            }
            value = Math.round(value * 100)/100;
            console.log("onBlur ", value);
            console.log("commuteEntries: ", commuteEntries);
            updateParking(commuteEntries?.id, value);
            setParkingValue(value.toFixed(2));
            
          }}
        >
        </TextField>
      </Flex>
      <Flex 
        alignItems={"center"}
        width="unset"
        basis="0"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        {...getOverrideProps(overrides, "tolls")}
        >
        <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="row"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            size="small"
            children="$"
          ></Text>
        <TextField
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="100px"
          height="unset"
          gap="unset"
          alignItems="unset"
          // grow="0"
          // shrink="0"
          // basis="1"
          position="relative"
          padding="0px 30px 0px 0px"
          whiteSpace="pre-wrap"
          size="small"
          variation="quiet"
          value={tollValue}
          // {...getOverrideProps(overrides, "tolls")}
          onChange={(e) => {
            let value = e.target.value;
            setTollValue(value);
          }}
          onBlur={(e) => {
            let inputValue = e.target.value;
            let value = 0;
            if(inputValue.includes("+") || inputValue.includes("-")){
              // split by addition first
              const additions = inputValue.split("+");
              for (let x in additions) {
                // split and calculate subtraction
                const subtractions = additions[x].split("-");
                let addValue = parseFloat(subtractions[0]);
                subtractions.shift();
                for (let y in subtractions){
                  addValue -= parseFloat(subtractions[y]);
                }
                value += addValue;
              }
            } else {
              value = parseFloat(inputValue);
            }

            if(isNaN(value)){
              value = 0;
            }
            if (value < 0){
              value = 0;
            }
            value = Math.round(value * 100)/100;
            console.log("onBlur ", value);
            console.log("commuteEntries: ", commuteEntries);
            updateTolls(commuteEntries?.id, value);
            setTollValue(value.toFixed(2));
            
          }}
        >
        </TextField>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"$"}${commuteEntries?.total ? commuteEntries.total.toFixed(2): '0.00'}`}
        {...getOverrideProps(overrides, "total")}
      ></Text>
    </Flex>
  );
}
