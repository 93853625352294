import * as React from "react";
import { CarData } from "../models";
import {
  getOverrideProps
} from "@aws-amplify/ui-react/internal";
import { Flex, Menu, MenuItem, Text } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";

export default function CustomCarTableEntry(props) {
  const { carData, openEditDialog, overrides, ...rest } = props;

  return (
    <Flex
      gap="6px"
      direction="row"
      width="1000px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="9px 10px 9px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CarTableEntry")}
    >
      <Menu size="small">
        <MenuItem
        onClick={() => {
          openEditDialog(carData?.usersID, carData?.id);
        }}>
          Edit Entry
        </MenuItem>
        <MenuItem
        onClick={async () => {
          try {
            await DataStore.delete(CarData, carData?.id);
          } catch (err) {
            console.error(err)
          }
        }}>
          Delete Entry
        </MenuItem>
      </Menu>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.year}
        {...getOverrideProps(overrides, "year")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.carName}
        {...getOverrideProps(overrides, "carName")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.make}
        {...getOverrideProps(overrides, "make")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.model}
        {...getOverrideProps(overrides, "model")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.licensePlate}
        {...getOverrideProps(overrides, "licensePlate")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.yearStartMileage}
        {...getOverrideProps(overrides, "odometerStart")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="20px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100px"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={carData?.yearEndMileage}
        {...getOverrideProps(overrides, "odometerEnd")}
      ></Text>
    </Flex>
  );
}