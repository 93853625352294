import { Auth } from "aws-amplify";
import React from "react";
import btn from '../SignIn/btn_google_signin_dark_normal_web@2x.png'
import { Button } from "@aws-amplify/ui-react";

function SignIn({loading}) {
    return (
        <div className='App' width="100%" style={({marginTop: "1vh"})}>
            <h3>Commute App Login</h3>
            <Button isLoading={loading} variation="link" onClick={() => Auth.federatedSignIn({ provider:"Google" })}> 
                <img src={btn} alt="Google Sign In button"
                    className="googleSignIn"
                    style={{height:"45px", width:"190px"}}/>
            </Button>
        </div>
        
    )
}

export default SignIn;